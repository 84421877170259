// Resources
import { assignmentsResource } from 'redux/resources/assignments';
import { calibrationSessionsResource } from 'redux/resources/calibrationSessions';
import { phoneCallsResource } from 'redux/resources/calls';
import { checklistsResource } from 'redux/resources/checklist';
import { checklistAnswersResource } from 'redux/resources/checklistAnswers';
import { checklistDefinitionsResource } from 'redux/resources/checklistDefinitions';
import { checklistScoresResource } from 'redux/resources/checklistScores';
import { clientInteractionsResource } from 'redux/resources/clientInteractions';
import { clientsResource } from 'redux/resources/clients';
import { commentsResource } from 'redux/resources/comments';
import { commentTemplatesResource } from 'redux/resources/commentTemplates';
import { communicationTablesResource } from 'redux/resources/communicationTables';
import { customFieldsResource } from 'redux/resources/customFields';
import { dashboardRowsResource } from 'redux/resources/dashboardRows';
import { dashboardsResource } from 'redux/resources/dashboards';
import { dashboardWidgetsResource } from 'redux/resources/dashboardWidgets';
import { foldersResource } from 'redux/resources/folders';
import { integrationsResource } from 'redux/resources/integrations';
import { folderItemsResource } from 'redux/resources/items';
import { levelsResource } from 'redux/resources/levels';
import { notificationSettingsResource } from 'redux/resources/notificationSettings';
import { organizationsResource } from 'redux/resources/organizations';
import { phoneCallChainsResource } from 'redux/resources/phoneCallChains';
import { phoneCallTranscriptionPartsResource } from 'redux/resources/phoneCallTranscriptionParts';
import { phoneCallTranscriptionsResource } from 'redux/resources/phoneCallTranscriptions';
import { questionGroupsResource } from 'redux/resources/questionGroups';
import { questionsResource } from 'redux/resources/questions';
import { questionToGroupBindingsResource } from 'redux/resources/questionToGroupBindings';
import { reviewLibrariesResource } from 'redux/resources/reviewLibraries';
import { reviewLibraryItemsResource } from 'redux/resources/reviewLibraryItems';
import { reviewsResource } from 'redux/resources/reviews';
import { rolesResource } from 'redux/resources/roles';
import { salesPipelineStatusesResource } from 'redux/resources/salePipelineStatuses';
import { salePipelinesResource } from 'redux/resources/salesPipelines';
import { statusesResource } from 'redux/resources/status';
import { taskDefinitionsResource } from 'redux/resources/taskDefinitions';
import { tasksResource } from 'redux/resources/tasks';
import { textCommunicationPartsResource } from 'redux/resources/textCommunicationParts';
import { textCommunicationsResource } from 'redux/resources/textCommunications';
import { unitsResource } from 'redux/resources/units';
import { uploadedFilesResource } from 'redux/resources/uploadedFiles';
import { userAccountBindingsResource } from 'redux/resources/userAccountBindings';
import { usersResource } from 'redux/resources/users';
import { workPlanTaskConfigurationsResource } from 'redux/resources/workPlanTaskConfigurations';
import { workPlanTasksResource } from 'redux/resources/workPlanTasks';
import { workPlanFiltersResource } from 'redux/resources/workPlanFilters';
import { workPlanTaskSchedulesResource } from 'redux/resources/workPlanTaskSchedules';
import { workPlanTaskItemsResource } from 'redux/resources/workPlanTaskItems';
import { workPlanTaskAssignmentsResource } from 'redux/resources/workPlanTaskAssignments';
import { workPlanReviewerBindingsResource } from 'redux/resources/workPlanReviewerBindings';
import { workPlanFilterFieldsResource } from 'redux/resources/workPlanFilterFields';
import { appellationsResource } from 'redux/resources/appellations';
import { appellationActionsResource } from 'redux/resources/appellationActions';
import { appellationCommentsResource } from 'redux/resources/appellationComments';
import { appellationObjectionsResource } from 'redux/resources/appellationObjections';
import { assignerByAppellantRules } from 'redux/resources/apellationRule';
import { crmEntitiesResource } from 'redux/resources/crmEntities';
import { datasetsResource } from 'redux/resources/dataset';
import { promptsResource } from 'redux/resources/prompts';
import { promptsQuestionsResource } from 'redux/resources/promptsQuestions';
import { promptsCustomFieldsResource } from 'redux/resources/promptsCustomFields';
import { promptsQuestionsAllResource } from 'redux/resources/promptsQuestionsAll';
import { promptsCustomFieldsAllResource } from 'redux/resources/promptsCustomFieldsAll';
import { datasetsFileListResource } from 'redux/resources/datasetFileList';
import { datasetsBindingResource } from 'redux/resources/datasetBinding';
import { phoneCallsResourceV3 } from 'redux/resources/callsV3';
import { userCustomFieldsResource } from 'redux/resources/userCustomFields';
import { aiSettingsResource } from 'redux/resources/aiSettings';
import { communicationReviewsResource } from 'redux/resources/communicationReviews';
import { aiTaskAnalyticsErrorsResource } from '../resources/aiTaskAnalyticsError';
import { aiErrorsResource } from '../resources/aiErrors';

export default {
  communicationTablesResource: communicationTablesResource.reducer,
  textCommunicationPartsResource: textCommunicationPartsResource.reducer,
  textCommunicationsResource: textCommunicationsResource.reducer,
  dashboardsResource: dashboardsResource.reducer,
  dashboardRowsResource: dashboardRowsResource.reducer,
  dashboardWidgetsResource: dashboardWidgetsResource.reducer,
  usersResource: usersResource.reducer,
  organizationsResource: organizationsResource.reducer,
  phoneCallsResource: phoneCallsResource.reducer,
  reviewsResource: reviewsResource.reducer,
  commentsResource: commentsResource.reducer,
  checklistsResource: checklistsResource.reducer,
  checklistAnswersResource: checklistAnswersResource.reducer,
  checklistDefinitionsResource: checklistDefinitionsResource.reducer,
  assignmentsResource: assignmentsResource.reducer,
  questionToGroupBindingsResource: questionToGroupBindingsResource.reducer,
  unitsResource: unitsResource.reducer,
  integrationsResource: integrationsResource.reducer,
  questionGroupsResource: questionGroupsResource.reducer,
  clientInteractionsResource: clientInteractionsResource.reducer,
  questionsResource: questionsResource.reducer,
  promptsQuestionsResource: promptsQuestionsResource.reducer,
  promptsQuestionsAllResource: promptsQuestionsAllResource.reducer,
  promptsCustomFieldsAllResource: promptsCustomFieldsAllResource.reducer,
  promptsCustomFieldsResource: promptsCustomFieldsResource.reducer,
  tasksResource: tasksResource.reducer,
  taskDefinitionsResource: taskDefinitionsResource.reducer,
  clientsResource: clientsResource.reducer,
  rolesResource: rolesResource.reducer,
  notificationSettingsResource: notificationSettingsResource.reducer,
  reviewLibrariesResource: reviewLibrariesResource.reducer,
  reviewLibraryItemsResource: reviewLibraryItemsResource.reducer,
  levelsResource: levelsResource.reducer,
  foldersResource: foldersResource.reducer,
  folderItemsResource: folderItemsResource.reducer,
  calibrationSessionsResource: calibrationSessionsResource.reducer,
  customFieldsResource: customFieldsResource.reducer,
  userCustomFieldsResource: userCustomFieldsResource.reducer,
  statusesResource: statusesResource.reducer,
  salesPipelineStatusesResource: salesPipelineStatusesResource.reducer,
  salePipelinesResource: salePipelinesResource.reducer,
  checklistScoresResource: checklistScoresResource.reducer,
  phoneCallChainsResource: phoneCallChainsResource.reducer,
  phoneCallTranscriptionsResource: phoneCallTranscriptionsResource.reducer,
  phoneCallTranscriptionPartsResource: phoneCallTranscriptionPartsResource.reducer,
  userAccountBindingsResource: userAccountBindingsResource.reducer,
  workPlanTaskConfigurationsResource: workPlanTaskConfigurationsResource.reducer,
  workPlanTasksResource: workPlanTasksResource.reducer,
  workPlanFiltersResource: workPlanFiltersResource.reducer,
  workPlanTaskSchedulesResource: workPlanTaskSchedulesResource.reducer,
  workPlanTaskAssignmentsResource: workPlanTaskAssignmentsResource.reducer,
  workPlanTaskItemsResource: workPlanTaskItemsResource.reducer,
  workPlanReviewerBindingsResource: workPlanReviewerBindingsResource.reducer,
  workPlanFilterFieldsResource: workPlanFilterFieldsResource.reducer,
  uploadedFilesResource: uploadedFilesResource.reducer,
  commentTemplatesResource: commentTemplatesResource.reducer,
  appellationsResource: appellationsResource.reducer,
  appellationActionsResource: appellationActionsResource.reducer,
  appellationCommentsResource: appellationCommentsResource.reducer,
  appellationObjectionsResource: appellationObjectionsResource.reducer,
  assignerByAppellantRulesResource: assignerByAppellantRules.reducer,
  crmEntitiesResource: crmEntitiesResource.reducer,
  datasetsResource: datasetsResource.reducer,
  aiSettingsResource: aiSettingsResource.reducer,
  promptsResource: promptsResource.reducer,
  datasetsFileListResource: datasetsFileListResource.reducer,
  datasetsBindingResource: datasetsBindingResource.reducer,
  phoneCallsResourceV3: phoneCallsResourceV3.reducer,
  communicationReviewsResource: communicationReviewsResource.reducer,
  aiTaskAnalyticsErrorResource: aiTaskAnalyticsErrorsResource.reducer,
  aiErrorsResource: aiErrorsResource.reducer
};
