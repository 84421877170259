import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Menu, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import SRow from 'components/Standard/SRow';
import SCol from 'components/Standard/SCol';
import AiIcon from 'assets/images/ai-icon.svg';

const NavigationMenu = ({
  isSelfPage,
  canManageOrgSettings,
  canManageIntegrations,
  canViewAnalytics,
  canViewReviews,
  canViewOperatorAppellations,
  canAiAnalyzeClientInteractions,
  canAiAnalysisClientInteractionsInOrganization
}) => {
  const history = useHistory();
  const { id, tabName } = useParams();
  const { t } = useTranslation();

  const handleNavigation = ({ key }) =>
    history.push(`/user/${id}/${key}${history.location.search || ''}`);

  return (
    <SRow gutter={[0, 36]} style={{ marginBottom: '-18px', flexDirection: 'column' }}>
      {canViewAnalytics && (
        <SCol style={{ paddingBottom: 0 }}>
          <CategoryName>{t('userSettingsPage.categories.main')}</CategoryName>
          <StyledMenu selectedKeys={[tabName]} onClick={handleNavigation} mode="inline">
            <Menu.Item key="charts" data-testid="charts-tab-menu-item">
              {t('userAnalyticsPage.analyticsTabs.analytics')}
            </Menu.Item>

            {canViewReviews && (
              <>
                <Menu.Item key="reviews" data-testid="reviews-tab-menu-item">
                  {t('userAnalyticsPage.analyticsTabs.rates')}
                </Menu.Item>
                <Menu.Item key="comments" data-testid="comments-tab-menu-item">
                  {t('userAnalyticsPage.analyticsTabs.flagsAndComments')}
                </Menu.Item>
              </>
            )}
          </StyledMenu>
        </SCol>
      )}

      {canViewOperatorAppellations && (
        <SCol style={{ paddingTop: 0 }}>
          <StyledMenu selectedKeys={[tabName]} onClick={handleNavigation} mode="inline">
            <Menu.Item key="appeals" data-testid="appeals-tab-menu-item">
              {t('menu.appMenu.appeals')}
            </Menu.Item>
          </StyledMenu>
        </SCol>
      )}

      {isSelfPage && (
        <SCol>
          <CategoryName>{t('userSettingsPage.categories.settings')}</CategoryName>
          <StyledMenu selectedKeys={[tabName]} onClick={handleNavigation} mode="inline">
            <Menu.Item key="user-settings" data-testid="user-settings-tab-menu-item">
              {t('userSettingsPage.tabs.accountSettings.tabName')}
            </Menu.Item>

            {canManageOrgSettings && (
              <Menu.Item
                key="organization-settings"
                data-testid="organization-settings-tab-menu-item"
              >
                {t('organizationSettingsPage.tabs.organizationSettings.tabName')}
              </Menu.Item>
            )}

            {canManageIntegrations && (
              <Menu.Item
                key="integrations-settings"
                data-testid="integrations-settings-tab-menu-item"
              >
                {t('menu.userMenu.integrationsSettings')}
              </Menu.Item>
            )}
            {canAiAnalysisClientInteractionsInOrganization && (
              <Menu.Item key="custom-fields" data-testid="custom-fields-tab-menu-item">
                {t('menu.userMenu.customFields')}
              </Menu.Item>
            )}

            {canAiAnalyzeClientInteractions && canAiAnalysisClientInteractionsInOrganization && (
              <Menu.Item key="ai-settings" data-testid="ai-settings-tab-menu-item">
                {t('menu.userMenu.aiSettings')}
                <img
                  src={AiIcon}
                  alt="ai-icon"
                  style={{ marginLeft: '5px', paddingBottom: '2px' }}
                />
              </Menu.Item>
            )}

            {canAiAnalyzeClientInteractions && canAiAnalysisClientInteractionsInOrganization && (
              <Menu.Item key="dataset-ai" data-testid="dataset-ai-tab-menu-item">
                Dataset
                <img
                  src={AiIcon}
                  alt="ai-icon"
                  style={{ marginLeft: '5px', paddingBottom: '3px' }}
                />
              </Menu.Item>
            )}
          </StyledMenu>
        </SCol>
      )}
    </SRow>
  );
};

const StyledMenu = styled(Menu)`
  && {
    padding: 0 30px;
    border-right: none;
    li.ant-menu-item {
      font-weight: 500;
      font-size: 16px;
      &.ant-menu-item-selected {
        background: var(--blue_6);
        &.ant-menu-item-active {
          background: var(--blue_4);
        }
      }
      &.ant-menu-item-active:not(.ant-menu-item-selected) {
        color: var(--blue_6);
      }
    }
  }
`;

const CategoryName = styled(Typography.Text)`
  && {
    text-transform: uppercase;
    font-weight: 500;
    color: var(--black_6);
    display: block;
    padding: 8px 30px;
    font-size: 16px;
  }
`;

export default NavigationMenu;
