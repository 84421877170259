import React from 'react';
import { Col } from 'antd';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { SRow, SText } from '../Standard';
import { isCustomFieldVisible } from '../../core/utils/isCustomFieldVisible';
import { Info } from '../Info';
import CustomFieldValueView from '../CustomFieldValueView';
import AiIcon from '../../assets/images/ai-icon.svg';

const CustomFieldsView = ({
  clientInteraction,
  communicationChainCustomFields,
  customFieldsByKeys
}) => {
  const { t } = useTranslation();
  return (
    <SRow>
      {Object.keys(communicationChainCustomFields).map(
        item =>
          isCustomFieldVisible(customFieldsByKeys[item]) && (
            <Col key={item} span={24}>
              {!Object.keys(customFieldsByKeys).includes(item) && (
                <Info
                  tooltip={{
                    title: t('components.communicationInfoModal.emptyFieldDescription')
                  }}
                  iconProps={{
                    style: { color: 'var(--red_6)', marginRight: 4 }
                  }}
                />
              )}
              {get(customFieldsByKeys, `${item}.usedForAi`, false) === true && (
                <img
                  src={AiIcon}
                  alt="ai-icon"
                  style={{ marginRight: '5px', paddingBottom: '2px' }}
                />
              )}
              <SText strong>{get(customFieldsByKeys, `${item}.name`, `${item}`)}</SText>
              {`: `}
              <CustomFieldValueView
                customFields={clientInteraction?.customFields}
                customFieldsByKeys={customFieldsByKeys}
                customFieldKey={item}
                sliced={false}
              />
            </Col>
          )
      )}
    </SRow>
  );
};

export default CustomFieldsView;
