import { Col, Modal, Row, Skeleton, Tooltip, Typography } from 'antd';
import catWithSpinner from 'assets/video/cat_with_spinner.mp4';
import SButton from 'components/Standard/SButton';
import SCard from 'components/Standard/SCard';
import moment from 'core/moment';
import { INTEGRATIONS, SYNC_DATA_PERIODS } from 'core/utils/constants';
import { get, isEmpty, isEqual } from 'lodash';
import BackgroundJob from 'pages/BackgroundJob';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { amocrmStatusEndpoint } from 'core/api';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { setCurrentBackgroundJob } from 'redux/entities/backgroundJob/reducer';
import { integrationsResource } from 'redux/resources/integrations';
import SRow from 'components/Standard/SRow';
import SCol from 'components/Standard/SCol';
import Icon from 'components/Icon';
import { BookOpen } from 'react-feather';
import SText from 'components/Standard/SText';
import { getManualLinkInfo } from 'pages/UserPage/Integrations/utils';
import { getCurrentUser } from 'redux/selectors/users';
import {
  getNaumenDb,
  getStatusConnectNaumen,
  setNaumenDb
} from 'redux/entities/naumenIntegration/operations';
import {
  getEdnaDb,
  getStatusConnectEdna,
  setEdnaDb
} from 'redux/entities/ednaIntegration/operations';
import { integrationTypeToSyncDataHandler } from '../CreateIntegrationsPages/CreateSaasIntegrationPage';
import AmoFields from '../IntegrationSettingsModules/AmoFields';
import ManageUsers from '../IntegrationSettingsModules/ManageUserAccounts';
import NameAndType from '../IntegrationSettingsModules/NameAndType';
import SyncPeriod from '../IntegrationSettingsModules/SyncPeriod';
import HDEFields from '../IntegrationSettingsModules/HDEFields';
import { updateResource } from '../../../../core/redux/resources/updateResource';
import { setS2IntegrationData } from '../../../../redux/entities/s2Integration/operations';
import { setPyrusIntegrationData } from '../../../../redux/entities/pyrus/operations';
import StatusManager from '../IntegrationSettingsModules/StatusManager';

const { Title } = Typography;

const SaasIntegrationPage = () => {
  const mounted = React.useRef(false);
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const urlParams = location.pathname.split('/');
  const [integrationType, setIntegrationType] = useState(urlParams[urlParams.length - 2]);

  const { id: userId, integrationId } = useParams();
  const currentBackgroundJob = useSelector(
    state => state.backgroundJob.currentBackgroundJob,
    isEqual
  );
  const organizationId = useSelector(
    state => state.reduxTokenAuth.currentUser.attributes.user['organization-id']
  );
  const [loading, setLoading] = useState(true);
  const [loading_, setLoading_] = useState(false);
  const [whole, setWhole] = useState(false);
  const dispatch = useDispatch();
  const integration = useSelector(
    state => get(state.integrationsResource.byIds, integrationId),
    isEqual
  );

  const bitrixIntegrationEveningSynchronizedEnabled = useSelector(
    state =>
      get(
        getCurrentUser(state),
        'organization.settings.bitrixIntegrationEveningSynchronized.enabled',
        false
      ),
    isEqual
  );

  const bitrixIntegrationNightlySynchronizedEnabled = useSelector(
    state =>
      get(
        getCurrentUser(state),
        'organization.settings.bitrixIntegrationNightlySynchronized.enabled',
        false
      ),
    isEqual
  );

  const syncAtTime =
    (integration?.bitrixIntegrationNightlySynchronizedEnabled ||
    bitrixIntegrationNightlySynchronizedEnabled
      ? '04:00'
      : null) ||
    (integration?.bitrixIntegrationEveningSynchronizedEnabled ||
    bitrixIntegrationEveningSynchronizedEnabled
      ? '23:00'
      : null) ||
    '';

  const isDisabledSyncPeriod =
    integration?.bitrixIntegrationNightlySynchronizedEnabled ||
    bitrixIntegrationNightlySynchronizedEnabled ||
    integration?.bitrixIntegrationEveningSynchronizedEnabled ||
    bitrixIntegrationEveningSynchronizedEnabled ||
    integration?.integrationEveningSynchronizedEnabled ||
    false;

  const loadIntegration = async () => {
    setLoading(true);
    if (integration?.integrationType === 'amocrm') {
      const response = await fetch(amocrmStatusEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          integration_id: integrationId
        })
      });
      const data = await response.json();
      setLoading_(data?.fetch?.in_process);
      setWhole(data);
      return data;
    }

    if (integration?.integrationType === INTEGRATIONS.naumen.type) {
      const data = await getStatusConnectNaumen(integration.id);
      await getNaumenDb(integration.id).then(() => {
        updateResource({
          resource: {
            ...integration,
            ...{ integrationEveningSynchronizedEnabled: data?.fetch?.in_process }
          },
          dispatch,
          type: 'integrations'
        });
      });
      setLoading_(data?.fetch?.in_process);
      setWhole(data);
    }
    if (integration?.integrationType === INTEGRATIONS.edna.type) {
      const data = await getStatusConnectEdna(integration.id);
      await getEdnaDb(integration.id).then(() => {
        updateResource({
          resource: {
            ...integration,
            ...{ integrationEveningSynchronizedEnabled: data?.fetch?.in_process }
          },
          dispatch,
          type: 'integrations'
        });
      });
      setLoading_(data?.fetch?.in_process);
      setWhole(data);
    }
    await dispatch(
      integrationsResource.operations.loadByIdIntegration({
        id: integrationId,
        type: integrationType
      })
    );
    if (integration?.integrationType === INTEGRATIONS.naumen.type) {
      await getNaumenDb(integration.id).then(res => {
        updateResource({
          resource: { ...integration, ...res },
          dispatch,
          type: 'integrations'
        });
      });
    }
    if (integration?.integrationType === INTEGRATIONS.edna.type) {
      await getEdnaDb(integration.id).then(res => {
        updateResource({
          resource: { ...integration, ...res },
          dispatch,
          type: 'integrations'
        });
      });
    }
  };

  useEffect(async () => {
    mounted.current = true;
    await loadIntegration();
    setLoading(false);
    return () => {
      mounted.current = false;
    };
  }, []);

  const updateIntegration = useCallback(
    async ({ name, ...otherFields }) => {
      if (integration?.integrationType === INTEGRATIONS.s2.type) {
        setS2IntegrationData(integration.id, otherFields, name, organizationId);
      } else if (integration?.integrationType === INTEGRATIONS.pyrus.type) {
        setPyrusIntegrationData(integration.id, otherFields, name, organizationId);
      } else {
        dispatch(
          integrationsResource.operations.updateById({
            id: integrationId,
            name,
            endpoint: otherFields.endpoints,
            ...otherFields
          })
        );
        if (integration?.integrationType === INTEGRATIONS.naumen.type) {
          setNaumenDb(integration.id, otherFields);
        }
        if (integration?.integrationType === INTEGRATIONS.edna.type) {
          setEdnaDb(integration.id, otherFields);
        }
      }
    },
    [dispatch, integrationId]
  );

  const onDelete = useCallback(() => {
    Modal.confirm({
      title: t('integrationsSettingsPage.deleteConfirm.title'),
      okText: t('integrationsSettingsPage.deleteConfirm.ok'),
      onOk: async () => {
        setLoading(true);
        await dispatch(
          integrationsResource.operations.deleteById({
            id: integrationId,
            type: integration?.integrationType
          })
        );
        history.push(`/user/${userId}/integrations-settings`);
        setLoading(false);
      }
    });
  }, [dispatch, integrationId]);

  const onSyncFrom = useCallback(
    async syncFrom => {
      if (integration.integrationType === 'amocrm') {
        setLoading_(true);
      }
      if (
        integration?.integrationType === INTEGRATIONS.amocrm.type ||
        integration?.integrationType === INTEGRATIONS.naumen.type ||
        integration?.integrationType === INTEGRATIONS.edna.type
      ) {
        await dispatch(
          integrationTypeToSyncDataHandler[integration.integrationType]({
            id: integration.id,
            syncFrom
          })
        );
      } else {
        const { number, momentUnit } = SYNC_DATA_PERIODS[syncFrom];
        const fetchFromISO = moment()
          .subtract(number, momentUnit)
          .toISOString();
        await dispatch(
          integrationTypeToSyncDataHandler[integration?.integrationType]({
            id: integration.id,
            fetchFrom: fetchFromISO
          })
        );
      }
      if (
        integration?.integrationType === INTEGRATIONS.naumen.type ||
        integration?.integrationType === INTEGRATIONS.edna.type ||
        integration?.integrationType === INTEGRATIONS.s2.type ||
        integration?.integrationType === INTEGRATIONS.pyrus.type
      ) {
        await updateResource({
          resource: {
            ...integration,
            ...{ integrationEveningSynchronizedEnabled: true }
          },
          dispatch,
          type: 'integrations'
        });
      }
    },
    [integration?.integrationType, dispatch]
  );

  const onBackgroundJobDone = () => {};

  const onBackgroundJobFailed = () => {
    Modal.error({
      title: `Возникла ошибка при синхронизации данных с ${t(
        INTEGRATIONS[integration.integrationType].name
      )}`,
      content: 'Пожалуйста, переустановите приложение или напишите нам на info@qolio.io'
    });
  };

  const { manualTextLink, manualLink } = getManualLinkInfo(
    integration?.integrationType || integrationType
  );

  return (
    <Row>
      <Col span={24}>
        <Row gutter={[0, 32]} style={{ marginBottom: '-16px' }}>
          <Col span={24}>
            <Row gutter={[0, 20]} style={{ marginBottom: '-10px' }}>
              <Col span={24}>
                <SRow type="flex" wrap={false} justify="space-between">
                  <SCol flex="auto">
                    <Title level={4}>
                      {`${t('integrationsSettingsPage.integrationPage.title')}: ${
                        integration?.name ? integration.name : ''
                      }`}
                    </Title>
                  </SCol>
                  <SCol flex="none">
                    <Tooltip title={manualTextLink}>
                      <a target="_blank" rel="noreferrer" href={manualLink}>
                        <SRow>
                          <SCol display="flex" alignItems="center">
                            <Icon icon={BookOpen} marginTop="1px" marginRight="6px" size="20px" />
                            <SText ellipsis color="var(--link-color)">
                              {manualTextLink}
                            </SText>
                          </SCol>
                        </SRow>
                      </a>
                    </Tooltip>
                  </SCol>
                </SRow>
              </Col>

              <Col span={24}>
                {loading ? (
                  <SCard>
                    <Skeleton active />
                  </SCard>
                ) : (
                  <NameAndType
                    canEdit
                    onUpdate={updateIntegration}
                    onDelete={onDelete}
                    integration={integration}
                    onChangeIntegrationType={type => setIntegrationType(type)}
                    data={whole}
                  />
                )}
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row gutter={[0, 20]} style={{ marginBottom: '-10px' }}>
              <Col span={24}>
                <Title level={4}>{t()}</Title>
              </Col>
              <Col span={24}>
                {loading ? (
                  <SCard>
                    <Skeleton active />
                  </SCard>
                ) : (
                  <>
                    <SCol flex="auto">
                      <Title level={4}>
                        {t('integrationsSettingsPage.integrationPage.manualSync')}
                      </Title>
                    </SCol>
                    <SyncPeriod
                      disabled={
                        integration?.integrationType === 'amocrm' ? loading_ : isDisabledSyncPeriod
                      }
                      okText={t('integrationsSettingsPage.sync')}
                      onOk={onSyncFrom}
                      syncAtTime={syncAtTime}
                      type={integration?.integrationType}
                      integrationId={integrationId}
                    />
                  </>
                )}
              </Col>
            </Row>
          </Col>

          {(integration?.entityCustomFieldsImportEnabled ||
            integration?.integrationType === INTEGRATIONS.amocrm.type ||
            integration?.integrationType === INTEGRATIONS.naumen.type ||
            integration?.integrationType === INTEGRATIONS.edna.type ||
            integration?.integrationType === INTEGRATIONS.pyrus.type ) && (
            <Col span={24}>
              <Row gutter={[0, 20]} style={{ marginBottom: '-10px' }}>
                <Col span={24}>
                  <Title level={4}>{t('integrationsSettingsPage.integrationPage.fields')}</Title>
                </Col>
                <Col span={24}>
                  {loading ? (
                    <SCard>
                      <Skeleton active />
                    </SCard>
                  ) : (
                    <AmoFields
                      integration={integration}
                      integrationId={integrationId}
                      okText={t('integrationsSettingsPage.save')}
                    />
                  )}
                </Col>
              </Row>
            </Col>
          )}

          {integration?.integrationType === INTEGRATIONS.pyrus.type && (
            <Col span={24}>
              <Row gutter={[0, 20]} style={{ marginBottom: '-10px' }}>
                <Col span={24}>
                  <Title level={4}>Статусы</Title>
                </Col>
                <Col span={24}>
                  {loading ? (
                    <SCard>
                      <Skeleton active />
                    </SCard>
                  ) : (
                    <StatusManager
                      integration={integration}
                      integrationId={integrationId}
                      okText={t('integrationsSettingsPage.save')}
                    />
                  )}
                </Col>
              </Row>
            </Col>
          )}

          {integration?.integrationType === INTEGRATIONS.help_desk_eddy.type && (
            <Col span={24}>
              <Row gutter={[0, 20]} style={{ marginBottom: '-10px' }}>
                <Col span={24}>
                  <Title level={4}>{t('integrationsSettingsPage.integrationPage.fields')}</Title>
                </Col>
                <Col span={24}>
                  <HDEFields
                    integrationId={integrationId}
                    okText={t('integrationsSettingsPage.save')}
                  />
                </Col>
              </Row>
            </Col>
          )}

          <Col span={24}>
            <Row gutter={[0, 20]} style={{ marginBottom: '-10px' }}>
              <Col span={24}>
                <Title level={4}>{t('integrationsSettingsPage.integrationPage.users')}</Title>
              </Col>
              <Col span={24}>
                {loading ? (
                  <SCard>
                    <Skeleton active />
                  </SCard>
                ) : (
                  <ManageUsers
                    integrationId={integrationId}
                    integrationInProcess={false}
                    okText={t('integrationsSettingsPage.save')}
                    integrationType={integration?.integrationType}
                  />
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Modal
        title={currentBackgroundJob === 'process' ? t('backgroundJob.status.process') : null}
        visible={!isEmpty(currentBackgroundJob)}
        onCancel={() => dispatch(setCurrentBackgroundJob(null))}
        footer={
          <SButton onClick={() => dispatch(setCurrentBackgroundJob(null))} type="primary">
            {t('integrationsSettingsPage.proceed')}
          </SButton>
        }
      >
        {currentBackgroundJob && (
          <BackgroundJob
            text={
              <video autoPlay loop muted playsinline>
                <source src={catWithSpinner} type="video/mp4" />
              </video>
            }
            description={
              <>
                <div>{t('backgroundJob.description')}</div>
              </>
            }
            spinning={false}
            onDone={onBackgroundJobDone}
            onFailed={onBackgroundJobFailed}
          />
        )}
      </Modal>
    </Row>
  );
};

export default SaasIntegrationPage;
