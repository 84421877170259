import { Col, Row, Typography, Tooltip, Switch, Select, message } from 'antd';
import IconButton from 'components/IconButton';
import { ExternalLink } from 'react-feather';
import CommunicationInfoModal from 'components/CommunicationInfoModal';
import AttachTag from 'components/PhoneCallPlayer/RecordPlayer/AttachTag';
import SCard from 'components/Standard/SCard';
import UserPreview from 'components/UserPreview';
import { compact, findIndex, get, isEmpty, isEqual } from 'lodash';
import moment from 'moment';
import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import CopyLinkButton from 'components/CopyLinkButton';
import { CLIENT_INTERACTIONS_TYPES_LITERALS, PERMISSIONS } from 'core/utils/constants';
import SRow from 'components/Standard/SRow';
import DOMPurify from 'dompurify';
import { useDispatch, useSelector } from 'react-redux';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { operations } from 'redux/lists/usersListReducer';
import { clientInteractionsResource } from 'redux/resources/clientInteractions';
import { getCurrentUser, getUsersWithPermissions } from 'redux/selectors/users';
import { StyledSearch, StyledSelect } from '../../PhoneCallPlayer/styled';
import { customFieldsResource } from '../../../redux/resources/customFields';
import { getCustomFieldsByKeys } from '../../../redux/selectors/customFields';
import AnalysisCommunicationButton from '../../PhoneCallPlayer/RecordPlayer/AnalysisCommunicationButton';
import ShowReviewsButton from '../../PhoneCallPlayer/RecordPlayer/ComminicationReviews/ShowReviewsButton';

const loadUsers = ({ dispatch }) =>
  dispatch(
    operations.load({
      pagination: 'false',
      include: 'unit,role.levels',
      sort: 'last_name'
    })
  );

const CustomSelectDropdown = React.memo(({ menu, onSearch, onChange }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const userLoading = useSelector(state => state.usersResource.loading);

  useEffect(() => {
    loadUsers({ dispatch });
  }, []);

  return (
    <SCard
      loading={userLoading}
      headPadding="0 12px"
      bodyPadding="0px"
      rounded="4px"
      shadowed
      width="100%"
      maxWidth="340px"
      minWidth="340px"
      title={
        <StyledSearch
          allowClear
          placeholder={t('components.recordPlayer.phoneCallInformation.searchPlaceholder')}
          onSearch={value => onSearch && onSearch(value)}
          onChange={e => onChange && onChange(e.target.value)}
        />
      }
    >
      {menu}
    </SCard>
  );
});

const ConversationInfo = ({
  loading,
  textCommunication,
  reviewId,
  shouldLoad,
  showSystemEvents,
  setShowSystemEvents
}) => {
  const {
    startedAt,
    communicationId,
    communicationType,
    email,
    externalUrl,
    clientPhoneNumber
  } = textCommunication;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [search, setSearch] = React.useState('');
  const [operatorIsUpdated, setOperatorIsUpdated] = React.useState(false);

  const { loading: userLoading, failed } = useSelector(state => state.usersResource);
  const operator = useSelector(
    state => state.usersResource.byIds[textCommunication?.operatorId],
    isEqual
  );

  const customFieldsByKeys = useSelector(state => getCustomFieldsByKeys(state), isEqual);
  const checkDataInfoModalCustomFields = () => {
    if (isEmpty(customFieldsByKeys) && !isEmpty(textCommunication?.customFields))
      dispatch(customFieldsResource.operations.load({ pagination: false }));
  };
  const { Text } = Typography;

  const textCommunicationTitle = DOMPurify.sanitize(textCommunication.title, {
    USE_PROFILES: { html: false }
  });
  const reviewersById = useSelector(state =>
    getUsersWithPermissions(state, { permissions: [PERMISSIONS.CAN_PERFORM_CLIENT_INTERACTION] })
  );
  const currentUser = useSelector(getCurrentUser, isEqual);
  const currentUserPermissions = get(currentUser, 'role.permissions', []);
  const canAiAnalyzeClientInteractions = currentUserPermissions.includes(
    PERMISSIONS.CAN_AI_ANALYZE_CLIENT_INTERACTIONS
  );
  const reviewers = Object.values(reviewersById).filter(({ active }) => active);
  const addOperatorToReviewers = findIndex(reviewers, ({ id }) => id === operator?.id) === -1;

  const onSelectNewOperator = async userId => {
    await dispatch(
      clientInteractionsResource.operations.updateClientInteractionOperator({
        id: textCommunication.id,
        operatorId: userId
      })
    );
    message.success('Оператор изменен');
    setOperatorIsUpdated(userId);
  };

  return (
    <SCard
      loading={loading || isEmpty(textCommunication)}
      bodyPadding="14px 16px 13px 16px"
      style={{ marginBottom: 4 }}
    >
      <Row type="flex" align="middle" justify="flex-between">
        <Col style={{ marginTop: 6, flex: 'auto' }}>
          <Row type="flex" align="start" justify="start" gutter={[16, 0]}>
            <Col>
              <>
                <StyledSelect
                  onClick={() => {
                    if (failed) {
                      loadUsers({ dispatch });
                    }
                  }}
                  onChange={onSelectNewOperator}
                  defaultValue={
                    <UserPreview
                      userId={operator.id}
                      user={operator}
                      disabled
                      showAvatar
                      showUnit
                    />
                  }
                  value={operatorIsUpdated || operator.id}
                  key={operator.id}
                  loading={userLoading}
                  bordered={false}
                  showArrow
                  dropdownMatchSelectWidth={false}
                  getPopupContainer={node => node.parentNode}
                  dropdownClassName="change-operator-dropdown"
                  dropdownStyle={{ padding: 0 }}
                  dropdownRender={menu => (
                    <CustomSelectDropdown menu={menu} onSearch={setSearch} onChange={setSearch} />
                  )}
                >
                  {compact([addOperatorToReviewers && operator, ...reviewers])
                    .filter(
                      ({ name }) =>
                        isEmpty(search) || name?.toLowerCase()?.indexOf(search.toLowerCase()) >= 0
                    )
                    .map(({ id }) => (
                      <Select.Option key={id} value={id}>
                        <UserPreview
                          userId={id}
                          user={reviewersById[operator]}
                          disabled
                          showAvatar
                          showUnit
                        />
                      </Select.Option>
                    ))}
                </StyledSelect>
                <IconButton
                  tooltip={{
                    title: t('components.recordPlayer.phoneCallInformation.tooltipButtonInfo'),
                    overlayInnerStyle: {
                      width: '400px'
                    }
                  }}
                  button={{
                    icon: <QuestionCircleOutlined style={{ fontSize: 16 }} />,
                    size: 'icon'
                  }}
                />
              </>
            </Col>
          </Row>
          <Row type="flex" align="start" justify="start" gutter={[16, 0]}>
            <Col style={{ paddingTop: 2 }}>
              <Text style={{ marginRight: 8 }}>
                <Text strong>{t('components.textCommunicationContent.conversationInfo.date')}</Text>
                {moment(startedAt).format('DD/MM/YYYY HH:mm')}
              </Text>
            </Col>
            <Col style={{ paddingTop: 2 }}>
              <Text style={{ marginRight: 8 }}>
                <Text strong>
                  <Trans i18nKey={CLIENT_INTERACTIONS_TYPES_LITERALS[communicationType]} />
                  {email || communicationId ? ': ' : ''}
                </Text>
                {communicationType === 'email' ? email || communicationId : communicationId}
              </Text>
            </Col>
            {clientPhoneNumber && (
              <Col style={{ paddingTop: 2 }}>
                <Text style={{ marginRight: 8 }}>
                  <Text>
                    <Text strong>
                      {t('components.textCommunicationContent.conversationInfo.phone')}
                    </Text>
                    {clientPhoneNumber}
                  </Text>
                </Text>
              </Col>
            )}
          </Row>
        </Col>
        <Col>
          <Row type="flex" align="end" justify="flex-end" gutter={[16, 0]}>
            <Col>
              <AttachTag reviewId={reviewId} block={false} shouldLoad={shouldLoad} />
            </Col>
            <Col>
              <CopyLinkButton
                reviewId={reviewId}
                destinationId={textCommunication?.id}
                destinationType="text-communications"
              />
            </Col>
            {externalUrl && (
              <Col>
                <Tooltip title={t('components.recordPlayer.externalLink')}>
                  <a href={externalUrl} target="_blank" rel="noopener noreferrer">
                    <IconButton
                      shape="external-link"
                      icon={<ExternalLink size="16" />}
                      size="small"
                    />
                  </a>
                </Tooltip>
              </Col>
            )}
            {!isEmpty(textCommunication.customFields) && (
              <Col onClick={checkDataInfoModalCustomFields}>
                <CommunicationInfoModal
                  customFields={textCommunication?.customFields}
                  loading={loading}
                  id={textCommunication?.id}
                />
              </Col>
            )}
            {canAiAnalyzeClientInteractions && (
              <Col>
                <AnalysisCommunicationButton />
              </Col>
            )}
            <Col>
              <ShowReviewsButton clientInteractionId={textCommunication?.id} />
            </Col>
          </Row>
        </Col>
      </Row>
      {!isEmpty(textCommunicationTitle) && (
        <Row type="flex" align="start" justify="flex-start" gutter={[16, 0]}>
          <Col style={{ paddingTop: 2 }}>
            <Text style={{ marginRight: 8 }}>
              <Text strong>
                <Trans i18nKey="components.textCommunicationContent.conversationInfo.title" />
              </Text>
              {textCommunicationTitle}
            </Text>
          </Col>
        </Row>
      )}

      {!isEmpty(textCommunication?.email) && (
        <Row type="flex" align="start" justify="flex-start" gutter={[16, 0]}>
          <Col style={{ paddingTop: 2 }}>
            <Text style={{ marginRight: 8 }}>
              <Text strong>{`${t('general.email')}: `}</Text>
              {textCommunication?.email}
            </Text>
          </Col>
        </Row>
      )}

      <SRow type="flex" align="end" justify="flex-start" gutter={[16, 0]} paddingTop="2px">
        {!isEmpty(textCommunication?.communicationStatus) && (
          <Col flex="1 1 auto">
            <Text style={{ marginRight: 8 }}>
              <Text strong>{`${t('general.status')}: `}</Text>
              {textCommunication?.communicationStatus}
            </Text>
          </Col>
        )}
        <Col flex="0 1 auto">
          <Text>{t('general.showDetails')}</Text>
          &nbsp;
          <Switch checked={showSystemEvents} onChange={setShowSystemEvents} />
        </Col>
      </SRow>
    </SCard>
  );
};

export default ConversationInfo;
