import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons';
import { PERMISSIONS } from 'core/utils/constants';
import React, { useCallback, useState } from 'react';
import { Tooltip, message } from 'antd';
import { useSelector } from 'react-redux';
import IconButton from 'components/IconButton';
import { getCurrentUserPermissions } from 'redux/selectors/users';
import { saveAs } from 'file-saver';
import { useTranslation } from 'react-i18next';
import { downloadCallFromMediaUrl } from 'core/api';
import { get, isEmpty } from 'lodash';
import { getCredentialsNaumen } from '../../../redux/entities/naumenIntegration/operations';

const DownloadButton = ({ callId, recordUrl }) => {
  const { t } = useTranslation();
  const userHasPermission = useSelector(state =>
    getCurrentUserPermissions(state).includes(PERMISSIONS.CAN_DOWNLOAD_CLIENT_INTERACTION_MEDIA)
  );
  const getOrganizationId = useSelector(
    state => state.reduxTokenAuth.currentUser.attributes.user['organization-id']
  );
  const [saving, setSaving] = useState(false);
  const handleDownload = useCallback(async () => {
    setSaving(true);
    let auth = null;
    if (recordUrl && recordUrl.includes('.nau')) {
      auth = await getCredentialsNaumen(recordUrl, getOrganizationId);
    }
    try {
      const res = await fetch(downloadCallFromMediaUrl, {
        method: 'POST',
        body: JSON.stringify({ media_url: recordUrl, integration_id: callId}),
        headers: !isEmpty(auth)
          ? {
              Authorization: `Basic ${btoa(`${auth?.username}:${auth?.password}`)}`
            }
          : {}
      });
      if (get(res, 'status', 200) === 200) {
        const contentType = res.headers.get('content-type');

        if (contentType) {
          if (contentType.startsWith('audio/') || contentType.startsWith('video/')) {
            const blob = await res.blob();
            saveAs(blob, callId);
          }
        }
      }
    } catch (error) {
      console.log(error);
      message.error(t('aiSettingsPage.operations.operationError'));
    } finally {
      setSaving(false);
    }
  }, [callId]);

  if (!userHasPermission) return null;

  if (saving) {
    return <LoadingOutlined />;
  }
  return (
    <Tooltip title={t('components.recordPlayer.download')}>
      <IconButton
        icon={<DownloadOutlined />}
        shape="circle-outline"
        size="small"
        onClick={handleDownload}
      />
    </Tooltip>
  );
};

export default DownloadButton;
