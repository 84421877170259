import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import { Col, Row, Skeleton, Typography } from 'antd';
import SCard from '../../../components/Standard/SCard';
import UserCustomFieldsManager from './UserCustomFieldsManager';

const { Title } = Typography;
const UserCustomFieldsPage = () => {
  const { t } = useTranslation();

  const loading = useSelector(
    state =>
      state.usersResource.loadByIdStarted ||
      state.usersResource.loading ||
      state.users.loading ||
      state.questionGroupsAnalytics.loading ||
      state.questionsAnalytics.loading ||
      state.integrationsResource.loading,
    isEqual
  );

  return (
    <Row style={{ margin: '16px' }}>
      <Col span={24}>
        <Row gutter={[0, 32]}>
          <Helmet>
            <title>{t('pagesMeta.userAnalyticsPage.title')}</title>
          </Helmet>
          <Col span={24}>
            <Row gutter={[0, 20]}>
              <Col span={24}>
                <Title level={4}>
                  {t('integrationsSettingsPage.integrationPage.customFieldsSettings.title')}
                </Title>
              </Col>
              <Col span={24}>
                {loading ? (
                  <SCard>
                    <Skeleton active />
                  </SCard>
                ) : (
                  <UserCustomFieldsManager />
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default UserCustomFieldsPage;
