import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Modal, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { aiTaskAnalyticsErrorsResource } from '../../redux/resources/aiTaskAnalyticsError';
import STable from '../../components/Standard/STable';
import SText from '../../components/Standard/SText';
import ClientInteractionLink from '../../components/ClientInteractionLink';
import { isJsonStr } from '../ClientInteractionsPage/components/ColumnsRender';
import { COMMUNICATION_TYPES_BACK } from '../../core/utils/constants';

const TooltipText = ({ title, children }) => (
  <Tooltip title={title}>
    <SText
      style={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'normal',
        maxHeight: '64px',
        display: 'block'
      }}
    >
      {children}
    </SText>
  </Tooltip>
);
const ModalTaskAnalyticsError = ({
  isOpen,
  setIsModalOpen,
  taskId,
  loadAnalyticsError,
  loading,
  analyticsError = [],
  meta = {},
  aiError = []
}) => {
  const { t } = useTranslation();

  const { page = 1, totalCount = 0, totalPages = 25 } = meta;
  useEffect(() => {
    if (taskId) loadAnalyticsError({ taskId, include: 'ai_errors' });
  }, [taskId]);

  const tableConfig = {
    columns: [
      {
        title: '',
        dataIndex: 'id',
        key: 'id',
        fixed: 'left',
        width: 30,
        render: (id, record) => {
          const communicationType = record?.attributes?.communicationType;
          const clientInteractionType = record?.attributes?.clientInteractionType;

          const normalizedKey = clientInteractionType?.split('::')[1];

          const decamelizeUpperCase = str =>
            str
              .replace(/([A-Z])([A-Z][a-z])/g, '$1_$2')
              .replace(/([a-z])([A-Z])/g, '$1_$2')
              .toUpperCase();
          return (
            <ClientInteractionLink
              id={id}
              communication={{
                communicationType,
                clientInteractionType: COMMUNICATION_TYPES_BACK[decamelizeUpperCase(normalizedKey)]
              }}
              useDrawer={false}
              isNewPage
            />
          );
        }
      },
      {
        title: 'Текст ошибки',
        dataIndex: 'text',
        key: 'text',
        align: 'center',
        render: (text, record) => {
          const aiErrorData = record?.relationships?.aiError?.data;

          const errorEntries = Array.isArray(aiErrorData)
            ? aiErrorData.map(item => aiError[item?.id]?.errorText).filter(Boolean)
            : [aiError[aiErrorData?.id]?.errorText].filter(Boolean);

          const textError = errorEntries
            .map(entry => {
              if (isJsonStr(entry)) {
                try {
                  return JSON.stringify(JSON.parse(entry), null, 2);
                } catch {
                  return entry;
                }
              }
              return entry;
            })
            .join('; ');

          return <TooltipText title={textError}>{textError}</TooltipText>;
        }
      },
      {
        title: 'Тип ошибки',
        dataIndex: 'type',
        key: 'type',
        align: 'center',
        render: (type, record) => {
          const aiErrorData = record?.relationships?.aiError?.data;

          const errorTypes = Array.isArray(aiErrorData)
            ? aiErrorData.map(item => aiError[item?.id]?.errorType).filter(Boolean)
            : [aiError[aiErrorData?.id]?.errorType].filter(Boolean);

          const typeError = errorTypes.join('; ');

          return <TooltipText title={typeError}>{typeError}</TooltipText>;
        }
      }
    ]
  };
  return (
    <Modal
      title={t('aiSettingsPage.taskSettings.communicationList')}
      centered
      visible={isOpen}
      onCancel={() => setIsModalOpen(false)}
      footer={null}
      width={755}
    >
      <STable
        dataSource={Object.values(analyticsError)}
        rowKey="id"
        size="small"
        loading={loading}
        columns={tableConfig.columns}
        border
        pagination={{
          page,
          totalCount,
          totalPages,
          showSizeChanger: true,
          pageSizeOptions: ['10', '25', '50']
        }}
      />
    </Modal>
  );
};

const mapStateToProps = state => {
  return {
    analyticsError: state.aiTaskAnalyticsErrorResource.byIds.resources,
    aiError: state.aiErrorsResource.byIds,
    meta: state.aiTaskAnalyticsErrorResource.byIds.meta,
    loading: state.aiTaskAnalyticsErrorResource.loading
  };
};

const mapDispatchToProps = {
  loadAnalyticsError: aiTaskAnalyticsErrorsResource.operations.load
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalTaskAnalyticsError);
