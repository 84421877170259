import { createReduxResource } from 'core/redux/resources';
import decamelize from 'decamelize-keys-deep';
import camelizeKeys from 'camelcase-keys-deep';
import api from '../../core/api';
import { aiErrorsResource } from './aiErrors';
import { processJsonApiCollection } from '../../core/jsonapi';

export const aiTaskAnalyticsErrorsResource = createReduxResource({
  name: 'aiTaskAnalyticsErrors',
  customOperations: {
    load: {
      callEndpoint: async ({ ...options }) => {
        const response = await api.getAiTaskAnalyticsErrors(decamelize({ ...options }));

        return {
          body: {
            resources: response.body.data,
            meta: camelizeKeys(response.body.meta),
            aiErrors: response.body?.included
          }
        };
      },
      onSuccess: ({ result, dispatch }) => {
        if (result?.aiErrors)
          dispatch(aiErrorsResource.actions.loadSucceed(processJsonApiCollection(result.aiErrors)));
        return result;
      }
    }
  }
});
