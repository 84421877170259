import { createSlice } from '@reduxjs/toolkit';

export const resourceDefaultState = {
  loading: false,
  failed: false,
  errors: [],
  byIds: {}
};

const reducerDefinitions = {
  // LOAD
  loadStarted: state => ({ ...state, loading: true }),
  loadSucceed: (state, { payload }) => ({
    ...state,
    loading: false,
    byIds: { ...payload }
  }),
  loadFailed: (state, { payload }) => ({
    ...state,
    failed: true,
    errors: [...state.errors, payload]
  }),

  // LOAD BY ID
  loadByIdStarted: (state, { payload }) => {
    const item = state.byIds[payload.id] || [];
    return {
      ...state,
      loadByIdStarted: true,
      byIds: { ...state.byIds, [payload.id]: { ...item, loading: true } }
    };
  },
  loadByIdSucceed: (state, { payload }) => {
    const item = state.byIds[payload.id] || {};
    // trully updating item
    const byIds = { ...state.byIds };
    delete byIds[payload.id];

    return {
      ...state,
      loadByIdStarted: false,
      byIds: { [payload.id]: { ...item, loading: false, ...payload }, ...byIds }
    };
  },
  loadByIdFailed: (state, { payload }) => ({
    ...state,
    loadByIdStarted: false,
    failed: true,
    errors: [...state.errors, payload]
  }),

  // CREATE
  createStarted: state => {
    return { ...state, createStarted: true, failed: false, errors: [] };
  },
  createSucceed: (state, { payload }) => {
    return {
      ...state,
      createStarted: false,
      byIds: { ...state.byIds, [payload.id]: payload }
    };
  },
  createFailed: (state, { payload }) => ({
    ...state,
    createStarted: false,
    failed: true,
    errors: [...state.errors, payload?.errors]
  }),

  // UPDATE
  updateByIdStarted: (state, { payload }) => {
    const item = state.byIds[payload.id] || [];
    return {
      ...state,
      errors: [],
      updateByIdStarted: true,
      byIds: { ...state.byIds, [payload.id]: { ...item, loading: true } }
    };
  },
  updateByIdSucceed: (state, { payload }) => {
    const item = state.byIds[payload.id] || [];
    return {
      ...state,
      errors: [],
      failed: false,
      updateByIdStarted: false,
      byIds: { ...state.byIds, [payload.id]: { ...item, loading: false, ...payload } }
    };
  },
  updateByIdFailed: (state, { payload }) => ({
    ...state,
    updateByIdStarted: false,
    failed: true,
    errors: [...state.errors, payload.error],
    byIds:
      payload?.id && state.byIds[payload.id]
        ? { ...state.byIds, [payload.id]: { ...state.byIds[payload.id], loading: false } }
        : state.byIds
  }),

  // DELETE
  deleteByIdStarted: (state, { payload }) => {
    const item = state.byIds[payload.id] || [];
    return {
      ...state,
      deleteByIdStarted: true,
      byIds: { ...state.byIds, [payload.id]: { ...item, loading: true } }
    };
  },
  deleteByIdSucceed: (state, { payload }) => {
    const byIds = { ...state.byIds };
    delete byIds[payload.id];
    return { ...state, deleteByIdStarted: false, byIds };
  },
  deleteByIdFailed: (state, { payload }) => ({
    ...state,
    deleteByIdStarted: false,
    failed: true,
    errors: [...state.errors, payload]
  }),
  clearErrors: state => ({
    ...state,
    failed: false,
    errors: []
  })
};

export const createResourceReducer = ({ name, additionalReducer, defaultState }) => {
  return createSlice({
    name,
    reducers: { ...reducerDefinitions, ...additionalReducer },
    initialState: { ...resourceDefaultState, ...defaultState }
  });
};
